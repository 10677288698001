/************************ Global CSS *************************/

/* Define @font-face for Inter Medium */
@font-face {
  font-family: 'Inter Medium';
  src: url('../assets/font/Inter-Medium.eot');
  src: url('../assets/font/Inter-Medium.eot?#iefix') format('embedded-opentype'),
    url('../assets/font/Inter-Medium.woff2') format('woff2'),
    url('../assets/font/Inter-Medium.woff') format('woff'),
    url('../assets/font/Inter-Medium.ttf') format('truetype'),
    url('../assets/font/Inter-Medium.svg#Inter-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* Define @font-face for Inter Regular */
@font-face {
  font-family: 'Inter Regular';
  src: url('../assets/font/Inter-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* **************** Core UI Update***************************  */

/* Sidebar Header Section */
.sidebar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255);
  padding: 0 0 0 0;
}

.sidebar-brand-business-name {
  text-align: center;
  color: #5e5e62;
  font-weight: 600;
  background: rgb(255 255 255);
}

.sidebar-brand-user-name {
  text-align: center;
  color: #5e5e62;
  font-weight: 400;
  font-size: 13px;
  background: rgb(255 255 255);
}

/* sidebar-logo */
.sidenav_header {
  color: #0157f6;
  font-weight: 500;
}

/* Sidebar Section */
.sidebar {
  --cui-sidebar-bg: #ffffff00;
}

/* Sidebar Navigation Item List Section */
.sidebar-nav {
  background-color: white;
  display: flex;
  min-height: 72%;
  overflow: hidden;
}
/* Side bar Text */
.sidebar-nav .nav-link {
  color: #5e5e62 !important;
  font-family: 'Inter Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0em;
  text-align: left;
  transition: background 0.00000000000000000000000000000005s ease, color 0.00000000005s ease;
  padding: 8px !important;
}

/* Sidebar Nav Active */
.sidebar-nav .nav-link.active {
  color: white !important;
  background: #0157f6 !important;
  border-radius: 10px;
  height: 40px;
  padding: 8px !important;
  font-family: 'Inter Medium';
}

/* Nav Link Page Path Text */
.sidebar-nav .nav-link path {
  fill: #5e5e62;
}

/* Nav Link Page Path Text Active*/
.sidebar-nav .nav-link.nav-link.active path {
  fill: rgb(255, 255, 255);
}

/* Sidebar List Item  */
.simplebar-content-wrapper .nav-item {
  width: 90%;
}

/* List Item Section */
.simplebar-content {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 7px;
  height: 100%;
  overflow: hidden !important;
  justify-content: flex-end;
}
.simplebar-track {
  display: none !important;
}

/* Icon And Text Color */
.sidebar-nav .nav-link .nav-icon {
  color: black;
  padding-left: 10px;
  width: 40px;
}
.custom-title-class {
  background-color: red !important;
  color: white;
  font-size: 70px !important;
}
.custom-tooltip {
  position: relative;
  display: inline-block;
  background-color: #0157f6 !important;
}

.tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: red !important;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px; /* Use half of the width to center the tooltip */
}

.custom-tooltip:hover .tooltip-text {
  visibility: visible;
}

/* Hover Effect on Icon */
.sidebar-nav .nav-link:hover .nav-icon {
  color: inherit;
}

/* ********************** CORE UI Background ******************* */
.bg-light {
  --cui-bg-opacity: 1;
  background-color: #f5f5f5 !important;
}

html:not([dir='rtl']) .sidebar.sidebar-fixed:not(.sidebar-end) {
  box-shadow: 0px 0px 10px rgba(187, 187, 187, 0.4) !important;
  overflow: hidden;
  background-color: white !important;
}

/* *************************** Scroll Bar CSS ************************************* */
/* Define the scrollbar track */
::-webkit-scrollbar {
  width: 0px;
  /* Width of the scrollbar */
  height: 5px;
}

/* Define the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #0157f6;
  /* Color of the thumb */
  border-radius: 10px;
  /* Rounded corners for the thumb */
}

/* Define the scrollbar track when hovering over it */
::-webkit-scrollbar-track:hover {
  background-color: #ddd;
  /* Color of the track on hover */
}

/* Define the scrollbar thumb when hovering over it */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Color of the thumb on hover */
}

/* Loader Disable */
.spinner-border {
  display: none;
}

/* Sidebar  */
.sidebar {
  width: 250px;
}
.sidebar-open {
  margin-left: 250px; /* Adjust this value based on your sidebar width */
}
.sidebar-close {
  margin-left: 0px; /* Adjust this value based on your sidebar width */
}
.sidebar-toggler::before {
  background-color: white !important;
  background-image: url('../assets/images/icons/Surgical SVG.svg');
  background-size: 40%;
  background: white;
  height: 0px !important;
}
.sidebar-toggler {
  background-color: white;
  background-image: url('../assets/images/icons/Surgical SVG.svg');
  background-repeat: no-repeat;
  height: 30px !important;
  flex: 0 0 50px !important;
  background-position: center;
}
.sidebar-toggler:hover {
  background-color: white;
  height: 30px !important;
}
.sidebar-toggler button {
  background-image: url('../assets/images/icons/Surgical SVG.svg');
  background-size: 40%;
  background: white;
  height: 30px !important;
}
.sidebar-toggler button:hover {
  display: block;
  background: white !important;
  content: '';
  background-image: url('../assets/images/icons/Surgical SVG.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 30px !important;
}
.sidebar-toggle-img {
  background-color: white;
  height: 45px;
  cursor: pointer;
  /* margin-top:10px; */
}
.sidebar-toggle-main-img-section {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.sidebar-toggle-main-img {
  width: 55%;
  cursor: pointer;
}

.sidebar-narrow-unfoldable:not(:hover) .sidebar-toggler,
.sidebar-narrow .sidebar-toggler {
  position: unset !important;
}
.sidebar-nav {
  padding-left: 6px !important;
  overflow: hidden !important;
}

/* Sidebar Toggler  */
/* .sidebar-toggle {
  display: none;
} */

/* Menu Icon */
/* .d-md-down-none {
  display: none;
} */

/* Deskotp Media Query */
@media only screen and (min-width: 1561px) {
  /* Default Page CSS */
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

/* Laptop Media Query */
@media only screen and (max-width: 1560px) and (min-width: 1441px) {
  /* Default Page CSS */
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1366px) {
  /* Default Page CSS */
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 1365px) and (min-width: 1280px) {
  /* Default Page CSS */
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 1279px) and (min-width: 1024px) {
  /* Default Page CSS */
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  /* Default Page CSS */
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .sidebar-nav {
    min-height: 77% !important;
  }
}

/*Moblie Media Query */
@media only screen and (max-width: 767px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  /* Sidebar Toggler on Mobile */
  /* .sidebar-toggle {
    display: block;
    margin: 20px;
  } */

  /* Menu Button */
  .d-none {
    padding-left: 20px;
    display: flex !important;
  }
  .sidebar-nav {
    min-height: 82% !important;
  }
}

.admin-user-table-body table tr,
th {
  white-space: nowrap;
  /* width: 100%; */
}
th {
  padding: 10px;
}
