/* default padding and margin css */
* {
  padding: 0;
  margin: 0;
}

.modalloaders {
  position: relative;
  bottom: 33px;
  left: 90%;
  width: 30px;
  margin: 8px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, #0157f6 90%, #8c8c8c00);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 100%;
  animation: l7 1s infinite linear;
}

@keyframes l7 {
  33% {
    background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
  }
  50% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
  }
  66% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
  }
}
.register_Npi_suggestion_dropdown {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 13px;
  font-family: 'Inter Regular';
  /* min-width: 400px; */
  border-radius: 5px 5px 0 0;
  overflow: scroll;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.register_Npi_suggestion_dropdown thead tr {
  background-color: #0157f6;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  border-radius: 8px;
}

.register_Npi_suggestion_dropdown th,
.register_Npi_suggestion_dropdown td {
  padding: 2px 5px;
}

.register_Npi_suggestion_dropdown tbody tr {
  border-bottom: 1px solid #dddddd;
}

.register_Npi_suggestion_dropdown tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.register_Npi_suggestion_dropdown tbody tr:last-of-type {
  border-bottom: 2px solid #0157f6;
}

.register_Npi_suggestion_dropdown tbody tr:hover {
  background-color: #0157f6;
  color: #fff;
  cursor: pointer;
}

.register-btn-loading {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: auto;
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
}
.register-btn-loading-contact {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: auto;
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
  border: 2px solid #0157f6;
}
@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }
  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }
  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}
@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }
  49.99% {
    transform: scaleY(1) rotate(135deg);
  }
  50% {
    transform: scaleY(-1) rotate(0deg);
  }
  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

/* default input remove increase decrease button  */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.term_and_conditions_list {
  list-style-type: disc;
  padding-left: 40px;
  font-size: 14px;
  font-family: 'Inter Regular';
  color: #47464a;
}
.term_and_conditions_list li {
  margin-top: 5px;
}
/* register form page css */
.register-form-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcfcfc;
  min-height: 100vh;
  padding: 20px 0px;
}

/* checkbox error message css */
.error-message {
  color: red;
  font-size: 12px;
}

/* require text css */
.required-star {
  color: red;
}

/* register form css */
.register-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  background-color: white;
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 17px 0px #1018282e;
  padding: 35px 0px;
  border-radius: 8px;
  width: 40%;
  min-width: auto;
}

/* surgi-cal logo css */
.register-logo {
  color: #0157f6;
  font-weight: bolder;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0em;
}

/* create account heading css */
.create-account-heading {
  font-family: 'Inter Medium';
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  color: #1b1b1f;
}

/* enter your details heading css */
.enter-details {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  color: #5e5e62;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-family: 'Inter Regular';
  font-weight: 400;
}

/* register form section css*/
.register-form-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 18px;
  margin-top: 10px;
}

/* firstname and lastname input content css */
.input-name-content {
  display: flex;
  justify-content: space-between;
  width: 75%;
}
.input-names-content {
  width: 75%;
}
.input-phone {
  font-family: 'Inter Medium';
  display: flex;
  flex-direction: column;
  color: #414141;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  /* width: 48%; */
  width: 100%;
}
.phone-box {
  border: 1px solid #e4e2e6 !important  ;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.phone-box-disable {
  border: none;
  display: flex;
}

/* firstname and lastname input css */
.input-name-div {
  display: flex;
  flex-direction: column;
  width: 45%;
  position: relative;
}

/* name error message css */
.error-message-name {
  position: relative;
  color: red;
  font-family: 'Inter Medium';
  font-size: 12px;
}

/* email, user-type and password input div css */
.input-div {
  display: flex;
  flex-direction: column;
  width: 75%;
}

/* firstname and lastname label css */
.input-label {
  font-family: 'Inter Medium';
  font-size: 14px;
  color: #47464a;
  line-height: 20px;
  letter-spacing: 0em;
  margin-bottom: 5px;
}

/* firstname and lastname input css */
.input-name {
  font-family: 'Inter Regular';
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #5e5e62;
  padding-left: 10px;
  border: 1px solid #e4e2e6;
  padding: 10px 15px;
  border-radius: 8px;
}
.input-contact-section {
  color: #5e5e62;
  border: 1px solid #e4e2e6;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* email, user-type and password input div css */
.input-div {
  position: relative;
}

/* email input label css */
.input-email-label {
  font-family: 'Inter Medium';
  font-size: 14px;
  color: #47464a;
  line-height: 20px;
  letter-spacing: 0em;
  margin-bottom: 5px;
  margin-top: 5px;
}

/* user type input label css */
.input-userType-label {
  font-family: 'Inter Medium';
  font-size: 14px;
  color: #47464a;
  line-height: 20px;
  letter-spacing: 0em;
  margin-bottom: 5px;
}
.form-control:focus {
  /* color: white !important; */
  box-shadow: none !important;
}

.register-dropdown-header i {
  font-size: 10px;
  position: absolute;
  color: #acacac;
  right: 10px;
  top: 15px;
}

/* email error message css */
.error-message-email,
.error-message-address {
  position: relative;
  color: red;
  font-size: 12px;
  font-family: 'Inter Medium';
}

/* user type error message css */
.error-message-usertype {
  position: relative;
  font-family: 'Inter Medium';
  color: red;
  font-size: 12px;
}

/* password error message css */
.error-message-pasword {
  position: relative;
  color: red;
  font-size: 12px;
  font-family: 'Inter Medium';
}

/* email input css */
.input-email-text {
  font-family: 'Inter Regular';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #121212;
  padding-left: 10px;
  border: 1px solid #e4e2e6;
  padding: 10px 15px;
  border-radius: 8px;
}

/* city input css */
.input-city {
  font-family: 'Inter Regular';
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #5e5e62;
  padding-left: 10px;
  border: 1px solid #e4e2e6;
  padding: 7px 15px;
  border-radius: 8px;
}

/**** custom dropdown for User Type code start ****/
.register-custom-dropdown {
  position: relative;
  display: inline-block;
}

/* **************address input ************ */
.address-input {
  font-family: 'Inter Regular';
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #5e5e62;
  padding-left: 10px;
  border: 1px solid #e4e2e6;
  padding: 10px 15px;
  border-radius: 8px;
}

.input-name-content-zip {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

/* user type dropdown option label css */
.register-dropdown-header {
  padding: 10px;
  border: 1px solid #e4e2e6;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Inter Regular';
  color: #121212;
  font-weight: 400;
}

/* user type dropdown options css */
.register-dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #e4e2e6;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 1000;
  font-size: 14px;
  font-family: 'Inter Regular';
  color: #121212;
  font-weight: 400;
}

/* user type dropdown options css */
.register-dropdown-option {
  margin: 2px;
  padding: 8px;
  cursor: pointer;
  font-family: 'Inter Regular';
}

/* user type dropdown options onHover css */
.register-dropdown-option:hover {
  background-color: #0157f6;
  border-radius: 8px;
  color: #fcfcfc;
}

/**** custom dropdown for User Type code end ****/

/* password input label css*/
.input-pass-label {
  font-family: 'Inter Medium';
  font-size: 14px;
  color: #47464a;
  line-height: 20px;
  letter-spacing: 0em;
  margin-bottom: 5px;
}

/* password input css */
.register-input-pass {
  font-family: 'Inter Regular';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #121212;
  border: 1px solid #e4e2e6;
  padding: 10px 45px 10px 15px;
  border-radius: 8px;
  width: 100%;
}

/* accept terms & condition container css*/
.input-terms-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 75%;
  gap: 10px;
  position: relative;
}

/* accept terms & condition checkbox error css */
.checkbox-error-message {
  position: absolute;
  top: 20px;
  font-family: 'Inter Medium';
  color: red;
  font-size: 12px;
}

/* accept terms & condition checkbox css */
.register-checkbox {
  border: 2px solid #919094;
  /* Border style */
  border-width: 3px;
  /* Adjust the value to your desired border width */
  height: 18px;
  width: 18px;
  cursor: pointer;
}

/* accept terms & condition label css */
.register-remember-text {
  font-family: 'Inter Medium';
  color: #0157f6;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  font-weight: 400;
  /* cursor: pointer; */
}

.register-two-fac-text {
  font-family: 'Inter Medium';
  color: #47464a;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  font-weight: 400;
  /* cursor: pointer; */
}

/* password toggle span css */
.password-toggle {
  position: absolute;
  top: 45px;
}

/* already have an account and login option css */
.register-footer-btn-section {
  display: flex;
  flex-direction: column;
  width: 75%;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

/* register btn css */
.register-btn {
  background-color: #0157f6;
  color: white;
  border: none;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  width: 100%;
  padding: 10px 18px 10px 18px;
  border-radius: 8px;
}

/* already have an account and login label section css */
.have-accont {
  font-family: 'Inter Regular';
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #5e5e62;
}

/* login link css */
.register-sign-up-link {
  color: #0157f6;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
  margin-left: 6px;
  font-family: 'Inter Medium';
}

/* Verificition Email Notification */
.email-sent-message-section {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* email message sent content css */
.email-sent-message-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0px 0.5px 2px 3px #1018280f;
  margin: 10px 20px;
}

/* email sent message css */
.email-sent-message {
  font-family: 'Inter Medium';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 27px;
  text-align: center;
}

/* user email css in send email */
.sent-email {
  color: #0157f6;
  font-weight: 600;
}

/* mail sent message container css */
.loading-mail-sent-messsage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

/* mail sent message content css */
.loading-mail-sent-content {
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0px 0.5px 2px 3px #1018280f;
  margin: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* mail sent message text css */
.loading-mail-sent-messsage-text {
  font-family: 'Inter Medium';
  align-items: center;
  font-size: 27px;
  text-align: center;
}

/* term and condition popup css start */
.condition_main_page_section {
  margin: auto;
  padding: 25px;
}

.term_and_conditions_header_main_title {
  font-family: 'Inter Medium';
  font-weight: bold;
  font-size: 18px;
  color: #1b1b1f;
  /* margin-bottom: 20px; */
}

.term_condition_popup_header {
  padding: 10px;
  border-bottom: 1px solid #e4e2e6;
  justify-content: center;
}

.term_cross_btn {
  position: absolute;
  right: 10px;
  top: 18px;
  cursor: pointer;
}

.term_and_conditions_page_section {
  margin: auto;
  padding-top: 10px;
  border-radius: 10px;
}

.logo_section {
  margin-top: 20px;
  text-align: center;
}

.term_and_condition_page_container {
  height: 60vh;
  overflow: scroll;
}

/* For WebKit/Blink browsers (Chrome, Safari, Opera) */
::-webkit-scrollbar {
  width: 8px;
  /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #0157f6;
  /* Set the color of the scrollbar thumb */
  border-radius: 6px;
  /* Set the border radius of the scrollbar thumb */
}

.required-star_term {
  color: red;
  font-size: 14px;
  position: relative;
  bottom: 10px;
}

.required-star_sub_term {
  color: red;
  font-size: 14px;
  position: relative;
}

.term_and_conditions_main_title {
  font-family: 'Inter Medium';
  font-size: 30px;
  color: #1b1b1f;
  text-align: center;
  font-weight: bold;
  padding-left: 15px;
}

.term_and_conditions_title {
  font-family: 'Inter Medium';
  font-weight: bold;
  font-size: 18px;
  color: #1b1b1f;
}
.term_and_conditions_sub_titles {
  font-family: 'Inter Medium';
  font-size: 14px;
  color: #1b1b1f;
  margin-top: 10px;
  margin-left: 10px;
}
.term_and_conditions_sub_title_text {
  font-family: 'Inter Medium';
  font-size: 14px;
  color: #1b1b1f;
}
.term_and_conditions_title_hippa {
  font-family: 'Inter Medium';
  font-size: 18px;
  font-weight: bold;
  color: #0c0c0c;
}
h5.term_and_conditions_title.subtitle {
  font-size: 14px;
  font-weight: bold;
  color: #0c0c0c;
}
.click-img-icon {
  width: 24px;
  cursor: pointer;
}

.term_and_condition_list {
  list-style: decimal-leading-zero;
}
.react-tel-input .form-control {
  width: 100% !important;
  border: none !important;
}
.term_and_conditions_sub_title {
  font-family: 'Inter Regular';
  font-size: 14px;
  color: #5e5e62;
  margin-left: 23px;
  margin-bottom: 10px !important;
}
.test {
  list-style: disc;
}
.go_back_section {
  display: flex;
  justify-content: center;
}

.go_back_section a {
  text-decoration: none;
}

.go_back_section_btn {
  background-color: #0157f6;
  color: white;
  text-decoration: none;
  border-radius: 10px;
  padding: 5px;
}

.register_suggestion_dropdown {
  list-style: none;
  width: 100%;
  /* background: red; */
  padding: 0.6rem;
  margin-top: 5px;
  border-radius: 10px;
  border: 1px solid #ddd;
  max-height: 13rem;
  overflow: scroll;
}

.register_suggestion_dropdown li {
  padding: 0.2rem;
  padding-left: 5px;
}

.register_suggestion_dropdown li:hover {
  background: #0157f6;
  color: white;
  padding: 0.2rem;
  border-radius: 8px;
  padding-left: 5px;
}

/* term and condition popo css end */

@media only screen and (max-width: 1440px) and (min-width: 1366px) {
  .register-form {
    padding: 35px 15px;
  }
}

@media only screen and (max-width: 1365px) and (min-width: 1280px) {
  .register-form {
    padding: 35px 15px;
  }

  .create-account-heading {
    font-size: 24px;
  }

  .input-name {
    padding: 5px 15px;
  }

  .input-city {
    padding: 5px 15px;
  }

  .register-dropdown-header i {
    top: 10px;
  }
}

@media only screen and (max-width: 1279px) and (min-width: 1024px) {
  .register-form {
    padding: 35px 15px;
  }

  .create-account-heading {
    font-size: 24px;
  }

  .input-name {
    padding: 5px 15px;
  }

  .input-city {
    padding: 5px 15px;
  }

  .register-dropdown-header i {
    top: 10px;
  }

  .input-label {
    font-size: 12px;
  }

  .input-email-label {
    font-size: 12px;
  }

  .input-userType-label {
    font-size: 12px;
  }

  .input-pass-label {
    font-size: 12px;
  }

  .input-email-text {
    padding: 5px 15px;
  }

  .register-dropdown-header {
    padding: 5px 15px;
  }

  .register-input-pass {
    padding: 5px 15px;
  }

  .email-password-icon {
    bottom: 21px;
  }

  .registerPage_Usertype {
    bottom: 10px;
  }
}

@media screen and (1024px <=width <=1279px) {
  .input-name-content {
    width: 90% !important;
  }
  .input-names-content {
    width: 90% !important;
  }

  .input-div {
    width: 90% !important;
  }

  .input-terms-div {
    width: 90% !important;
  }

  .register-footer-btn-section {
    display: flex;
    flex-direction: column;
    width: 75%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  /* register form css */
  .register-form {
    width: 60%;
    min-width: auto;
  }

  .input-name-content {
    width: 90% !important;
  }
  .input-names-content {
    width: 90% !important;
  }

  .input-div {
    width: 90% !important;
  }

  .input-terms-div {
    width: 90% !important;
  }
}

/*Moblie Media Query */
@media only screen and (360px <=width <=740px) {
  /* register error message css */
  .checkbox-error-message {
    font-size: 11px !important;
    /* bottom: -31px !important; */
  }
}

@media only screen and (max-width: 767px) {
  /* register form css */
  .register-form {
    min-width: auto;
    width: 95%;
  }

  /* register error message css */
  .checkbox-error-message {
    font-size: 11px !important;
    top: 21px;
  }

  /* create account heading css */
  .create-account-heading {
    text-align: center;
  }

  /* firstname and lastname input content css */
  .input-name-content {
    flex-direction: column;
    gap: 23px;
    width: 90%;
  }
  .input-names-content {
    width: 90%;
  }

  /* register form section css*/
  .register-form-section {
    gap: 23px;
  }

  .register-footer-btn-section {
    margin-top: 20px;
  }

  /* firstname and lastname input css */
  .input-name-div {
    width: auto;
  }

  /* email, user-type and password input div css */
  .input-div {
    width: 90%;
  }

  /* accept terms & condition container css*/
  .input-terms-div {
    width: 90%;
  }
}
