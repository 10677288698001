/* email verification section css */
.verify-email-section {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.verificationMessageText{
  font-family: 'Inter Medium';
}
/* email verification container css */
.verify-email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0px 0.5px 2px 3px #1018280f;
  margin: 10px 20px;
}
/* email verification content css */
.verify-email-content {
  font-family: 'Inter Medium';
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  font-size: 27px;
  position: relative;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* proceed to login css */
.verify-btn {
  top: 60px;
  background: #0157f6;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 22px;
  font-size: 20px;
  animation: fadeIn 1s ease forwards; /* Apply the fadeIn animation when the button is visible */
}
