/* reset password form page css */
.reset-form-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fcfcfc;
}

/* reset password form css */
.reset-password-form {
  min-width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  background-color: white;
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 17px 0px #1018282e;
  padding: 80px 30px;
  border-radius: 8px;
}
.password-checklist {
  font-size: 12px;
}

/* reset password logo content css */
.reset-form-logo-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* reset password logo text css */
.reset-logo-text {
  color: #0157f6;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0px;
}
/* reset password logo text css */
.reset-logo-text {
  font-size: 24px;
}

/* reset password heading content css */
.reset-password-heading-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  padding-bottom: 20px;
}
/* reset password text heading css */
.reset-text-heading {
  font-size: 32px;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 0em;
  color: black;
}
/* wlecome text css */
.welcome-text {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  color: #5e5e62;
}

/* reset password form container css */
.reset-form-input-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 25px;
}

/* input container css */
.reset-password-input-content {
  display: flex;
  flex-direction: column;
  width: 75%;
  position: relative;
}
/* password error css */
.password-error {
  font-family: 'Inter Medium';
  font-size: 12px;
  color: red;
  position: absolute;
  bottom: -36px;
}
/* confirm password error css */
.password-error-confirmPass {
  padding: 10px;
  font-family: 'Inter Medium';
  font-size: 12px;
  color: red;
  position: absolute;
  top: 65px;
}
/* reset password label css */
.reset-password-label {
  font-size: 14px;
  color: #47464a;
  line-height: 20px;
  letter-spacing: 0em;
  font-family: 'Inter Medium';
  line-height: 19.6px;
  margin-bottom: 5px;
}

/* eye icon css */
.email-password-icon {
  position: absolute;
  bottom: 15px;
  right: 18px;
  color: #acacac;
  font-size: 14px;
  cursor: pointer;
}
.modal-text-success {
  text-align: center; /* Center align the text */
  font-weight: bolder;
  font-family: Inter Medium;
  font-size: 25px; /* Adjust font size as needed */
  color: #0157f6; /* Green color for success messages, adjust as per your design */
  margin: 0; /* Remove any default margin */
  padding: 20px; /* Add padding for better readability */
  white-space: nowrap; /* Prevent text from wrapping to a new line */
  overflow: hidden; /* Hide any overflow beyond the container */
  text-overflow: ellipsis; /* Show an ellipsis (...) if the text overflows */
}

/* password input css */
.reset-password-input {
  font-family: 'Inter Regular';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #5e5e62;
  padding-left: 10px;
  border: 1px solid #e4e2e6;
  padding: 10px 15px;
  border-radius: 8px;
  padding-right: 40px;
  width: 100%;
}

/* change password btn div css */
.reset-change-btn-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

/* reset error message css */
.reset-error {
  position: absolute;
  font-family: 'Inter Medium';
  font-size: 12px;
  color: red;
  bottom: -24px;
}

/* change password btn css  */
.reset-change-btn {
  background-color: #0157f6;
  color: white;
  border: none;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  width: 75%;
  padding: 10px 18px 10px 18px;
  border-radius: 8px;
}

/* confirm password container css */
.confirmpassdiv {
  width: 100%;
  margin-left: 112px;
  padding: 10px 0px;
}

@media screen and (250px <= width <=354px) {
  /* reset password text heading css */
  .reset-text-heading {
    font-size: 20px;
  }
  /* wlecome text css */
  .welcome-text {
    font-size: 13px;
  }
  .reset-email-input-conten {
    width: 100%;
  }
  /* reset password form css */
  .reset-password-form {
    padding: 15px 0px;
    min-width: 90% !important;
  }
  /* confirm password container css */
  .confirmpassdiv {
    display: flex;
    justify-content: center;
    margin-left: 0px;
  }
}
@media screen and (355px <= width <=767px) {
  /* reset password text heading css */
  .reset-text-heading {
    font-size: 25px;
  }
  /* wlecome text css */
  .welcome-text {
    font-size: 14px;
  }
  /* input container css */
  .reset-password-input-content {
    width: 100%;
  }
  /* reset password form css */
  .reset-password-form {
    min-width: 90% !important;
  }
  /* confirm password container css */
  .confirmpassdiv {
    width: 100%;
    margin-left: 0px;
  }
  /* change password btn css  */
  .reset-change-btn {
    width: 100%;
  }
  /* reset password heading content css */
  .reset-password-heading-content {
    width: 100%;
    padding-bottom: 20px;
  }
}
