/* splash container css */
.splashContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100vh;
}

/***** splash animation css start*****/
@keyframes customFlashAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/***** splash animation css end*****/

/*/ Laptop Media Query /*/
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  /* splash container css */
  .splashContainer {
    width: 100% !important;
  }
}

/*Moblie Media Query */
@media only screen and (max-width: 767px) {
  /* splash container css */
  .splashContainer {
    width: 100% !important;
  }
  /* splash image css */
  .splashimg {
    width: 80%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 359px) {
  /* splash container css */
  .splashContainer {
    width: 100% !important;
  }
}
