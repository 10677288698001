/* default css  */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/***** splash animation css start*****/
@keyframes customFlashAnimation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/***** splash animation css end *****/

/* login page container css */
.login-form-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fcfcfc;
}

/* login form container css */
.login-form {
  min-width: 512px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  background-color: white;
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 17px 0px #1018282e;
  padding: 56px 30px;
  border-radius: 8px;
}

/* login form logo container */
.login-form-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* login form heading text container css */
.login-heading-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

/* Log in to your account text css */
.login-text {
  font-size: 32px;
  font-weight: 500;
  line-height: 38.4px;
  letter-spacing: 0em;
  color: #1b1b1f;
  font-family: 'Inter Medium';
  text-align: center;
}

/* welcome text css */
.welcome-text {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  color: rgba(94, 94, 98, 1);
  font-weight: 400;
  text-align: center;
  font-family: 'Inter Regular';
  font-weight: 400;
}

/* login form input container css */
.login-form-input-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 25px;
}

/* email container css */
.login-email-input-content {
  display: flex;
  flex-direction: column;
  width: 90%;
  position: relative;
}

/* email error message css */
.email-error-login {
  font-family: 'Inter Medium';
  font-size: 12px;
  color: red;
  bottom: -24px;
  position: absolute;
}

/* email label css */
.login-email-text {
  font-size: 14px;
  color: #47464a;
  line-height: 20px;
  letter-spacing: 0em;
  margin-bottom: 5px;
  font-weight: 500;
  font-family: 'Inter Medium';
}

/* email input css */
.login-email-input {
  font-family: 'Inter Regular';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #5e5e62;
  padding-left: 10px;
  border: 1px solid #e4e2e6;
  padding: 10px 15px;
  border-radius: 8px;
}

/* email input placeholder css */
.login-email-input::placeholder {
  color: #a3a3a3;
}

/* password container css */
.login-password-input-content {
  display: flex;
  flex-direction: column;
  width: 90%;
  position: relative;
}

/* password error message css */
.password-error {
  font-family: 'Inter Medium';
  font-size: 12px;
  color: red;
  position: absolute;
  bottom: -24px;
}

/* password label css */
.login-password-text {
  font-size: 14px;
  color: #47464a;
  line-height: 20px;
  letter-spacing: 0em;
  margin-bottom: 5px;
  font-weight: 500;
  font-family: 'Inter Medium';
}

/* email icon css */
.email-password-icon {
  position: absolute;
  bottom: 15px;
  right: 18px;
  color: #acacac;
  font-size: 14px;
  cursor: pointer;
}

/* password input css */
.login-password-input {
  font-family: 'Inter Regular';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #5e5e62;
  padding-left: 10px;
  border: 1px solid #e4e2e6;
  padding: 10px 15px;
  border-radius: 8px;
  width: 100%;
  padding-right: 40px;
}

/* remember forgot container css */
.login-remember-forget-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

/* rememberme div css start */
.login-remember-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* rememberme checkbox css  */
.login-checkbox {
  margin-right: 8px;
  height: 18px;
  width: 18px;
}

/* rememberme input css start */
.login-remember-content input[type='checkbox'] {
  border: 5px solid #919094;
  cursor: pointer;
}

/* rememberme label css */
.login-remember-text {
  color: #919094;
  font-size: 14px;
  letter-spacing: 0em;
  text-align: left;
  font-weight: 400;
  font-family: 'Inter Regular';
  cursor: pointer;
}

/* forgot passowrd css */
.login-forget-password {
  color: #0157f6;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  font-family: 'Inter Medium';
  font-weight: 500;
}

/* loggedIn login text css */
.login-btn-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

/* login submit btn css */
.login-btn {
  background-color: #0157f6;
  color: white;
  border: none;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  width: 90%;
  padding: 10px 18px 10px 18px;
  border-radius: 8px;
}

/* not have account text css */
.not-have-accont {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #5e5e62;
  font-family: 'Inter Regular';
}

/* signup link css */
.login-sign-up-link {
  color: #0157f6;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
  margin-left: 0px;
  font-family: 'Inter Medium';
}

/* Deskotp Media Query */
@media only screen and (min-width: 1561px) {

  /* login form container css */
  .login-form {
    width: 512px;
    /* Restore the original width for laptops and desktops */
  }
}
@media only screen and (max-width: 1440px) and (min-width: 1366px){ 
  .login-form-page {
    margin-top: 15px;
    margin-bottom: 15px;
    height: 95vh;
  }
  .login-form {
    min-width: 450px;
    padding: 35px 10px;
  }

 }

@media only screen and (max-width: 1365px) and (min-width: 1280px) {
  .login-form-page {
    margin-top: 15px;
    margin-bottom: 15px;
    height: 95vh;
  }

  .login-form {
    min-width: 450px;
    padding: 35px 10px;
  }
  .login-text {
    font-size: 20px;
  }
  .welcome-text{
    font-size: 16px;
  }
  .login-email-input{
    padding: 5px 15px;
  }
  .login-password-input{
    padding: 5px 15px;
  }
  .login-btn {
    padding: 7px 18px 7px 18px;
  }
  .login-email-text{
    font-size: 12px;
  }
  .login-password-text{
    font-size: 12px;
  }
  .email-password-icon {
    position: absolute;
    bottom: 10px;
}

}

@media only screen and (max-width: 1279px) and (min-width: 1024px) {
  .login-form-page {
    margin-top: 15px;
    margin-bottom: 15px;
    height: 95vh;

  }

  .login-form {
    min-width: 450px;
    padding: 35px 10px;
  }
  .login-text {
    font-size: 24px;
  }
  .welcome-text{
    font-size: 16px;
  }
  .login-email-input{
    padding: 5px 15px;
  }
  .login-email-text{
    font-size: 12px;
  }
  .login-password-text{
    font-size: 12px;
  }
  .login-password-input{
    padding: 5px 15px;
  }
  .login-btn {
    padding: 7px 18px 7px 18px;
  }
  .email-password-icon {
    position: absolute;
    bottom: 10px;
}
}

/*/ Laptop Media Query /*/
@media only screen and (max-width: 1023px) and (min-width: 768px) {

  /* login form container css */
  .login-form {
    width: 512px;
  }
}

/*Moblie Media Query */
@media only screen and (max-width: 767px) {

  /* login form container css */
  .login-form {
    min-width: 90%;
    width: 95%;
    margin: 10px;
  }

  /* remember forgot container css */
  .login-remember-forget-section {
    flex-direction: column;
  }

  /* forgot passowrd css */
  .login-forget-password {
    text-align: left;
    justify-content: flex-start;
  }

  /* email container css */
  .login-email-input-content {
    width: 100%;
  }

  /* password container css */
  .login-password-input-content {
    width: 100%;
  }

  /* remember forgot container css */
  .login-remember-forget-section {
    align-items: flex-start;
    width: 100%;
  }

  /* rememberme div css start */
  .login-remember-content {
    justify-content: flex-start;
  }

  /* forgot password div css */
  .forgotpassword_div {
    width: 100%;
    text-align: center;
    padding-top: 5px;
  }

  /* remember and forgot password text css end */
  /* login button css */
  .login-btn {
    width: 100%;
  }

  /* login text css */
  .login-text {
    font-size: 26px;
  }

  /* welcome text css */
  .welcome-text {
    font-size: 14px;
  }

  /* login heading css */
  .login-heading-content {
    gap: 6px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 414px) {

  /* login form container css */
  .login-form {
    min-width: 90%;
    width: 100%;
  }

  /* remember forgot container css */
  .login-remember-forget-section {
    flex-direction: column;
    text-align: left;
    justify-content: left;
  }
}